@import url('https://fonts.googleapis.com/css2?family=Coiny&display=swap');

body {
  background-color: #E9E4D1;
}

.title-wrapper{
  height: 100vh;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  margin-top: -64px;
}

.sean-bot-description{
  font-family: "Coiny";
  padding: 24px;
  background-color: #F2EFE4;
  font-size: 24px;
  border-radius: 32px;
  max-width: 35vw;
}

.chat-wrapper-wrapper{
  height: 100vh;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sean-bot-title{
  font-family: "Coiny";
  background-color: #CCC7B3;
  width: auto;
  font-size: 124px;
  display:inline-block;
  padding: 24px 32px 24px 32px;
  border-radius: 32px;
  margin-bottom: 18px;
}

.app-wrapper{
  display: flex;
}

.message-wrapper {
  height: 80%;
  overflow-y: scroll;
  margin-left: 2em;
  margin-right: 2em;
}

.top-top-wrapper{
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2em 0 2em;
}

.menu-btn:hover{
  transform: scale(2);
}

.top-bottom-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2em 0 2em;
}

.dummy{
  margin: auto;
  color: #F2EFE4;
}

.chat-wrapper {
  width: 800px;
  background-color: #F2EFE4;
  height: 80vh;
  border-radius: 25px;
}

.time{
  color: #100F0D;
  font-size: 16px;
}

.title{
  color: #100F0D;
  font-size: 24px;
}

.message-wrapper::-webkit-scrollbar {
  width: 0.25rem;
}


.message-wrapper::-webkit-scrollbar-thumb {
  background: white;
}

.form-wrapper {
  padding-top: 1em;
  width: 95%;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.5rem;
  margin: auto;
  height: 10%;
}


.submit-btn {
  display: none;
  background-color: white !important;
  
}

.top-wrapper{
  height: 10%;
  width: 100%;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root:before{
  border-bottom: None !important;
}
.css-q0jhri-MuiInputBase-root-MuiInput-root:after{
  border-bottom: None !important;
}

.text-field{
  height: 2.5em;
  width: 100%;
  display: flex;
  background-color: white;
  border-radius: 12px;
  color: #A6A39D !important;
  padding: 3px 10px 0px 15px;
  font-size: 16px;
  border: 1px solid #E9E4D1;
  box-shadow: 0px 4px 5px rgba(0,0,0, .25);
}

input:focus {outline:none;}

.css-z4rqyd-MuiButton-endIcon{
  margin: auto !important;
}

ul, li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 10px;
  position: relative;
  color: white;
  text-align: center;
}

.input-wrapper{
  width: calc(100% - 200px)

}

.message {
  display: flex;
  align-items: center;
}

.search-btn{
  position: relative;

}


.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: black;
  background: #FFFBF2;
  align-self: flex-end;
}
.received p {
  background: white;
  color: black;
}

img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 2px 5px;
}

.eyes-follow-tired {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.eyelid {
  position: absolute;
  width: 220px;
  height: 100px;
  background-color: white;
  z-index: 400;
  top: -120px;
  animation: blink 4.5s infinite ease-in alternate;
  animation-delay: 1s;
}
@keyframes blink {
  0% {
    top: -120px;
  }
  95% {
    top: -120px;
  }
  100% {
    top: -50px;
  }
}

.eyes {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.eye {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  background-color: #f3efef;
}

.pupil {
  position: absolute;
  background-color: #382e25;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  left: 50px;
  top: 35px;
}

.icon-wrap{
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.icon-wrap:hover{
  transform: scale(2) !important;
  transition: all 0.2s ease-in-out;
}

.social-icons-wrap{
  margin-top: 2em;
  display: flex;
  width: 25vw;
  justify-content: space-evenly;
}

.tiredness {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.header {
  font-size: 23px;
  margin-bottom: 10px;
  font-style: italic;
}

.dot-elastic {
 position: relative;
 width: 10px;
 height: 10px;
 border-radius: 5px;
 background-color: black;
 color: black;
 animation: dot-elastic 1s infinite linear;
}
.dot-elastic::before, .dot-elastic::after {
 content: "";
 display: inline-block;
 position: absolute;
 top: 0;
}
.dot-elastic::before {
 left: -15px;
 width: 10px;
 height: 10px;
 border-radius: 5px;
 background-color: black;
 color: black;
 animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
 left: 15px;
 width: 10px;
 height: 10px;
 border-radius: 5px;
 background-color: black;
 color: black;
 animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
 0% {
   transform: scale(1, 1);
 }
 25% {
   transform: scale(1, 1.5);
 }
 50% {
   transform: scale(1, 0.67);
 }
 75% {
   transform: scale(1, 1);
 }
 100% {
   transform: scale(1, 1);
 }
}
@keyframes dot-elastic {
 0% {
   transform: scale(1, 1);
 }
 25% {
   transform: scale(1, 1);
 }
 50% {
   transform: scale(1, 1.5);
 }
 75% {
   transform: scale(1, 1);
 }
 100% {
   transform: scale(1, 1);
 }
}
@keyframes dot-elastic-after {
 0% {
   transform: scale(1, 1);
 }
 25% {
   transform: scale(1, 1);
 }
 50% {
   transform: scale(1, 0.67);
 }
 75% {
   transform: scale(1, 1.5);
 }
 100% {
   transform: scale(1, 1);
 }
}

@media only screen and (max-width: 1200px) {
  .title-wrapper{
    margin-top: 2em;
    width: 100vw;
    height: auto;
  }
  .chat-wrapper-wrapper{
    width: 100vw;
    width: auto;
    padding-top: 1em;
    margin-top: 0;
    height: auto;
  }
  .app-wrapper{
    display:block;
  }
  .sean-bot-description{
    max-width: 80vw !important;
  }
}

@media only screen and (max-width: 750px) {

  .sean-bot-title{
    font-size: 60px;
  }
  .title-wrapper{
    margin-top: 2em;
    width: 100vw;
    height: auto;
  }
  .chat-wrapper-wrapper{
    width: 95vw;
    padding-top: 1em;
    margin: auto;
    padding-bottom: 1em;
    height: auto;
  }
  .chat-wrapper{
    height: 95vh;
    padding-bottom: 1em;
  }
  .app-wrapper{
    display:block;
    width: 100vw;;
  }
  .sean-bot-description{
    width: 100vw !important;
    max-width: auto;
    font-size: 20px;
  }
  .social-icons-wrap{
    width: 100vw;
  }
}